.top-not-found {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .rotated-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-30deg);
  }
  
  .rotated-div {
    position: relative;
    z-index: 1;
  }
  
  .rotated-div h1 {
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 11rem;
    margin-right: 4rem;
  }
  
  .notfound-button-container {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .notfound-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #d9f2eb;
    color: rgb(0, 0, 0);
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-weight: bold;
  }
  
  .notfound-button:hover {
    background-color: #8db2dc;
  }
  @media only screen and (max-width: 600px) {
    .rotated-div h1 {
      font-size: 1rem;
      margin-top: 10rem;
      margin-right: 2rem;
    }
  }